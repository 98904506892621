<template>
    <div id="app" style="color: #f9fbf8;" class="apps" >
        <router-view/>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                Category:[],
            }
        },
        created() {
            // 获取头部菜单内容


        },

        methods: {

        }
    }
</script>
<style>
    body {
        background-color: #353535 !important;
        /*height: 100vh;*/
        margin: 0;
        padding: 0;
        overflow-y: scroll
    }

    .ant-menu-horizontal {
        line-height: 46px;
        white-space: nowrap;
        border: 0;
        border-bottom: 0px solid #e8e8e8 !important;
        box-shadow: none;
        color: #f9fbf8;
    }

    .submenu-title-wrapper {
        /*background-color: #1b191a;*/
    }

    .ant-menu-submenu > .ant-menu {

        background-color: rgba(27, 25, 26, 0.9) !important;
        border-radius: 4px;
    }

    .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
        color: #fff !important;
        border-bottom: 0px solid #1890ff !important;
    }

    .ant-menu-item-selected {
        color: #fff !important;
    }

    .ant-menu-item-active {
        color: #fff !important;
    }

    .ant-menu-item-active a.router-link-exact-active {
        color: #fff !important;
    }
    .ant-menu-item-active a:hover {
        color: #fff !important;
    }

    .ant-menu {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5;
        font-feature-settings: 'tnum';
        margin-bottom: 0;
        padding-left: 0;
        padding-top: 30px;
        /*color: rgba(255, 255, 255, 1)!important;*/
        color: #6c6a6b !important;
        line-height: 0;
        list-style: none;
        background: rgba(0, 0, 0, 0) !important;
        outline: none;
        /*box-shadow: 0 2px 8px rgb(0 0 0 / 15%);*/
        transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
        zoom: 1;
    }
    .ant-menu-item > a {
        display: block;
        color: #6c6a6b!important;
    }
    .ant-menu-item > a:hover {
        display: block;
        color: #fff!important;
    }
    .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
        position: relative;
        top: 1px;
        display: inline-block;
        vertical-align: bottom;
        border-bottom: 0px solid transparent !important;
    }

    .apps {

        width: 1154px !important;
        min-width: 1157px;
        min-height: 575px;
        margin: 0px auto;
        /*height: 100vh;*/
    }



    #bottom {
        margin-top: 30px;
        text-align: center;
        color: #6c6a6b;
        font-size: 14px;
        position: relative;
        bottom: 20px;
        display: block;
    }
</style>
