<template>
    <span style="min-height: 60px; display: block">
        <div id="nav" v-if="Category.length>0">

            <router-link class="logo" to="/"><img src="../../public/logo.png" /></router-link>
            <a-menu :key="item.id" mode="horizontal" v-for="(item,index) in Category[0].child">

                <a-menu-item v-if="item.child.length == 0">
                        <span v-if="item.model_id == 3">
                            <a :href="JSON.parse(item.model_info_json).jumpurl.toString()"
                               target="_blank">{{item.title}}</a>
                        </span>
                    <span v-if="item.model_id !== 3">
                            <router-link
                                    :to="JSON.parse(item.model_info_json).jumpurl.toString()">{{item.title}}</router-link>

                        </span>

                </a-menu-item>

                <a-sub-menu v-if="item.child.length > 0">
                    <router-link :to="JSON.parse(item.model_info_json).jumpurl.toString()" class="submenu-title-wrapper"
                                 slot="title">{{item.title}}
                    </router-link>
                    <a-menu-item :key="items.id" v-for="(items,indexs) in item.child">
                        <router-link :to="JSON.parse(items.model_info_json).jumpurl.toString()">{{items.title}}
                        </router-link>
                    </a-menu-item>
                </a-sub-menu>

            </a-menu>
            <div class="top_switch"><span>中文</span>  |  English</div>
        </div>

    </span>
</template>

<script>
    import Config from "../config";

    export default {
        data() {
            return {

                Category: Config.data.Category,
                // current: ['7'],
            }
        },
        created() {
            // 获取头部菜单内容
            if (this.Category.length === 0) {
                this.getCategory();
            }


            console.log("Category", this.Category)

        },
        methods: {
            async getCategory(pid) {
                this.loading = true;
                this.Category = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        pid: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    Config.data.Category = result.data;
                    console.log("Config", Config.data.Category)
                    // Storage.nav = result.data;
                    // this.Category = Storage.nav;
                    this.loading = false;
                }

                this.loading = false;
            },
        }
    }
</script>

<style scoped>
    #nav {
        min-height: 50px;
    }

    #nav {
        color: #6c6a6b;
        display: -webkit-flex; /* Safari */
        display: flex;
        justify-content: flex-start;
        padding:5px ;
    }

    #nav a {
        justify-content: flex-start;
        align-items: flex-start;
        font-weight: bold;
        color: #6c6a6b;
        font-size: 14px;
        text-decoration: none;
        margin-right: 0px;
        margin-top: 0px;

    }

    #nav a.router-link-exact-active {
        color: #fff !important;
    }

    .logo {
        margin-top: 0px !important;
        margin-left: 5px;
        width:65px;
    }
    .logo img{
      width: 100%;
      height:auto;
    }
    .top_switch {
        width: 100%;
        float: right;
        text-align: right;
        padding-top: 15px;
        padding-right: 15px;

    }
    .top_switch span{
        font-weight: bold;
        color: #dddddd;
    }
    .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      height: 25px;
      line-height: 25px;
    }
</style>