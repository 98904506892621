<template>
    <div id="bottom">
      Copyright @ 2021 Mofa studio.All Rights Reserved<br/>
      <a href="http://beian.miit.gov.cn/">蜀ICP备2021018373号</a>
    </div>
</template>

<script>
    export default {
        name: "bottom"
    }
</script>

<style scoped>

</style>