<template>
    <div class="home">
        <topnav class="topnav"></topnav>
<!--                {{indexinfo}}-->
            <div class="index">
                <div class="backimg" v-bind:style="{backgroundImage:'url(' + server_url+item.bgurl + ')'}"  v-for="(item,index) in indexinfo" :key="index" :class="'index1_'+index">
                    <router-link :to="item.jumpurl">
                    <span class="moreinfo">
                        <h1>{{item.Name}}</h1>
                        <span v-html="item.textarea">

                        </span>
                    </span>
                    </router-link>
                </div>
            </div>
        <div style="text-align: center;margin-top: 10px;"><img src="../../public/index_bottom1.png"/></div>
        <bottomnav class="bottom"></bottomnav>
    </div>
</template>

<script>
    import Config from "../config";

    export default {
        data() {
            return {
                Category: [],
                indexinfo: [],
                server_url: Config.data.server_url,
                loading: false,

                // current: ['7'],
            }
        },
        created() {
            // 获取头部菜单内容
            // this.getCategory();
            this.getindex();
        },

        methods: {

            async getindex() {
                this.loading = true;
                this.indexinfo = [];

                let now = Date.now();
                let result = await this.$get("/api/app/indexContent/get", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.indexinfo = JSON.parse(result.data.content);
                    this.loading = false;
                }

                this.loading = false;
            },
        }
    }
</script>
<style scoped>

    .home {
        width: 1154px !important;
        overflow: hidden;
        position: absolute !important;
        padding-top: 50px;
        height: 100%;
        /*min-height: 700px;*/
        /*top:50%;*/
        /*margin-top: -388px;*/
        background-color: #000;
        display: block;
    }
    .ant-menu{

    }
    .topnav {
        margin-top: -45px;
    }

    .index {
        margin-top: 6px;
        position: relative !important;
        height: 575px;
    }
    .bottom {
        position: relative !important;
        bottom: 20px!important;
    }

    .index1_0 {
        top:0px;
        left: 0px;
        width: 180px;
        height: 286px;
        cursor: pointer !important;
    }

    .index1_1 {
        top:0px;
        left: 180px;
        width: 601px;
        height: 286px;
        cursor: pointer !important;
    }

    .index1_2 {
        top:286px;
        width: 601px;
        height: 286px;
        cursor: pointer !important;
    }

    .index1_3 {
        top:286px;
        left:601px;
        width: 180px;
        height: 286px;
        cursor: pointer !important;
    }

    .index1_4 {
        left: 781px;
        top:0px;
        width: 372px;
        height: 575px;
        cursor: pointer !important;
    }

    .backimg {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        position: absolute;
        display: inline-block;

    }
    .backimg a{
        display: block;
        width: 100%;
        height: 100%;
    }
    .backimg a:hover .moreinfo{
        display: block;

    }
    .moreinfo {
        position: absolute;
        width: 220px;
        background-color:rgba(255,255,255,0.1);
        padding: 5px;
        color: white;
        font-size: 12px;
        left: 10px;
        bottom: 10px;
        max-height: 150px;
        display: none;
    }

    .moreinfo h1 {
        font-size: 18px !important;
        color: white !important;
    }

</style>
