import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
 {
        path: '/production',
        name: 'production',
        component: () => import(/* webpackChunkName: "about" */ '../views/production.vue')
    },
    {
        path: '/join',
        name: 'join',
        component: () => import(/* webpackChunkName: "about" */ '../views/join.vue')
    },
    {
        path: '/culture',
        name: 'culture',
        component: () => import(/* webpackChunkName: "about" */ '../views/culture.vue')
    },
    {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
    },
    {
        path: '/article',
        name: 'article',
        component: () => import(/* webpackChunkName: "about" */ '../views/article.vue')
    }, {
        path: '/special',
        name: 'special',
        component: () => import(/* webpackChunkName: "about" */ '../views/special.vue')
    },{
        path: '/articleproduction',
        name: 'articleproduction',
        component: () => import(/* webpackChunkName: "about" */ '../views/articleproduction.vue')
    }
]

const router = new VueRouter({
    routes
})

export default router
