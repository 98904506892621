import Vue from 'vue'
import App from './App.vue'
import router from './router'

// antds
import Antd, {Modal} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false
// 网络部分
import Axios from 'axios'
import moment from "moment";
import Config from './config'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import topnav from "./components/nav"
import bottomnav from "./components/bottom"


Vue.component('topnav', topnav),
Vue.component('bottomnav', bottomnav),

(async function () {
  Axios.defaults.baseURL = Config.data.server_url
  Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
  Axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
  Vue.prototype.$get = Axios.get
  Vue.prototype.$post = Axios.post
  Vue.prototype.$config = Config.data



  Vue.use(Antd);
  Vue.config.productionTip = false
  window.vue = new Vue({
    router,
    // store,//使用store
    components: {App},
    // store,
    render: h => h(App)
  }).$mount('#app')

})();
// 处理接收内容

// 定义一个全局过滤器实现日期格式化
Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr*1000).format(pattern)
})

Axios.interceptors.response.use(response => {
  // function gotoLogin() {
  //   if (window.vue.$router.history.current.fullPath != "./#/login") {
  //     window.vue.$router.push({path: "./#/login"});
  //   }
  // }
  //
  // if (response.status != 200) {
  //   console.log('状态错误跳转到 登录', response.status)
  //   gotoLogin()
  //   return {code: -1}
  // }

  // 正常结果
  if (response.data.code  == -1) {
    Modal.error({
      title: response.data.msg
    });
    console.log('response', response.data.msg)
    return
  }
  if (response.data.code == 200) {

    return response.data;
  }

  // 不正常结果

  // if (response.data.code == -1) {
  //     // this.$message.info(response.data.msg);
  //     // alert(response.data.msg)
  //     // message.config({
  //     //     top: `100px`,
  //     //     duration: 2,
  //     //     maxCount: 3,
  //     // });
  //     // this.$message.error(response.data);
  // }
  if (response.data.code == -999) {
    // Modal.error({
    //     title: response.data.msg
    // });

    console.log('登录失效踢回登录', response.data)
    Storage.token = "";
    window.location = './#/login';
    // window.vue.$router.push({path: "./#/login"});
    return response.data;
  }
  // if (response.data.code == -666) {
  //     // Modal.error({
  //     //     title: response.data.msg
  //     // });
  //     Storage.token = "";
  //     console.log('登录失效踢回登录', response.data)
  //     window.vue.$router.push({path: "./#/login"});
  //     // Modal.error({
  //     //     title: response.data.msg
  //     // });
  //     return response.data;
  // }
  // // 口令错误跳转登录
  // if (response.data.code == Err.TOKEN_ERROR) {
  //     console.log('口令错误跳转到 登录', response.data)
  //     gotoLogin()
  //     return response.data;
  // }

  // 其他错误提示
  // console.log(ErrStr(response.data.code));
  return response.data;
}, error => {
  if (error.response) {
    this.$message.info(error.response.status);
    // alert()
    return Promise.reject(error.response.data);
  }
},function (error) {
  //处理不是200的结果
  if (401 === error.response.status) {
    window.location = './#/login';
  } else {
    return Promise.reject(error);
  }

});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
