export default {
    data: {
        debug: true,
        precision:1000,
        Category: [],
        server_url: "https://magicapi.mofa-studio.com/",
        site_name: "魔法动画工作室",
        // map_server_url: "https://apis.map.qq.com",
    }
}
